var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './NavList.styles';
import List from '../List/List';
import ListItem from '../List/ListItem';
import NavGroupItem from './NavGroupItem';
import NavItem from './NavItem';
var NavList = function NavList(_ref) {
  var className = _ref.className,
    menu = _ref.menu,
    menuType = _ref.menuType,
    parentType = _ref.parentType,
    openDropdownOnClick = _ref.openDropdownOnClick;
  return __jsx(List, {
    className: "nva-nav-list nva-nav-list--".concat(menuType, " ").concat(className)
  }, __jsx(React.Fragment, null, menu === null || menu === void 0 ? void 0 : menu.map(function (navItem, index) {
    var _navItem$groupCollect;
    return __jsx(ListItem, {
      key: navItem.sys.id
    }, !((_navItem$groupCollect = navItem.groupCollection) !== null && _navItem$groupCollect !== void 0 && _navItem$groupCollect.items) ? __jsx(NavItem, {
      item: navItem,
      menuType: menuType,
      parentType: parentType,
      index: index,
      totalItems: menu.length
    }) : __jsx(NavGroupItem, {
      navItem: navItem,
      openDropdownOnClick: openDropdownOnClick,
      menuType: menuType,
      index: index,
      totalItems: menu.length
    }));
  })));
};
export default styled(NavList).withConfig({
  componentId: "sc-gifgum-0"
})(["", ";"], styles);